<template>
  <div class="map-container">
    <div id="container"></div>
    <div id="panel"></div>
    <div id="loop"></div>

    <!-- <div class="place-wrapper">
      <div class="btn_item btn_hotel" :class="{'btn-active': endPlace.lng}" @click="handleHotel">
        VIP接待酒店
        <img :src="require('@/assets/images/map/icon_hotel.png')" alt="">
      </div>
    </div> -->

    <div class="btn_item btn_meeting" :class="{'btn-active': endPlace.lng}" @click="handleMeetting">
      <img :src="require('@/assets/images/map/icon_meetting.png')" alt="">
      <span>前往会场</span>
    </div>

    <transition>
      <div v-if="maskShow" class="mask" @click="handleCancal"></div>
    </transition>

    <!-- 酒店 会场选择 -->
    <transition name="slide">
      <div v-show="popupShowHotel" class="address-wrap">
        <div class="list-head" @click="handleCancal">
          <img :src="require('@/assets/images/map/icon_down.png')" alt="">
        </div>
        <div class="address-list">
          <div v-for="(item,index) in hotelList" :key="index" class="address-item" @click="handlePointHotel(item, index)">
            <div class="left">
              <span>{{item.label}}</span>
              <span>{{item.address}}</span>
            </div>
            <van-icon v-show="index===active_hotel" class="right" name="success" />
          </div>
        </div>
      </div>
    </transition>
    <transition name="slide">
     <div v-show="popupShowMeetting" class="address-wrap">
        <div class="list-head" @click="handleCancal">
          <img :src="require('@/assets/images/map/icon_down.png')" alt="">
        </div>
        <div class="address-list">
          <div v-for="(item,index) in mettingList" :key="index" class="address-item" @click="handlePointMeetting(item, index)">
            <div class="left">
              <span>{{item.label}}</span>
              <span>{{item.address}}</span>
            </div>
            <van-icon v-show="index===active_meetting" class="right" name="success" />
          </div>
        </div>
      </div>
    </transition>
    <!-- 地图选择 -->
    <transition name="slide">
      <div v-show="mapListShow" class="place-list">
        <div class="list-head" @click="handleCancal">
          <span>(调转到第三方平台地图后，可通过浏览器打开)</span>
          <img :src="require('@/assets/images/map/icon_down.png')" alt="">
        </div>
        <div v-for="(item,index) in mapList" :key="index" class="place-item" @click="handleOpenMap(item)">
          {{item.label}}
        </div>
        <p></p>
        <div class="place-item" @click="handleCancal">取消</div>  
      </div>
    </transition>

    <div v-show="endPlace.lng" @click="handleMap"  class="car-wrapper">
      打开地图
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
export default {
  data () {
    return {
      placeList: [],
      popupShow: false,
      popupShowHotel: false,
      popupShowMeetting: false,
      active_btn: '',
      hotelList: [
        { label: '长沙梅溪湖金茂精选酒店', lng: '112.904407', lat: '28.195549', address: '长沙市岳麓区梅溪湖环湖路1177路' },
        { label: '长沙市岳麓区湖南佳兴世尊酒店', lng: '112.932395', lat: '28.211977', address: '长沙市岳麓区金星中路247号' },
        // { label: '步步高福朋喜来登酒店', lng: '112.862222', lat: '28.195837', address: '长沙市岳麓区枫林三路1099号' }
      ],
      mettingList: [
        // { label: '开幕式|岳麓论坛',  lng: '112.906834', lat: '28.196149', address: '梅溪湖国际文化艺术中心大剧院', place: '梅溪湖国际文化艺术中心大剧院' },
        // { label: '先进计算&数字经济',  lng: '112.906834', lat: '28.196149', address: '梅溪湖国际文化艺术中心大剧院', place: '梅溪湖国际文化艺术中心大剧院'  },
        // { label: '安全技术创新论坛',  lng: '112.904265', lat: '28.195422', address: '长沙梅溪湖金茂精选酒店' },
        // { label: '提升数字素养 助理网络强省',  lng: '112.906392', lat: '28.196569', address: '梅溪湖国际文化艺术中心小剧院', place: '梅溪湖国际文化艺术中心小剧院' },
        // { label: '精准高新天下汇 逐梦万象新未来',  lng: '112.906834', lat: '28.196149', address: '梅溪湖国际文化艺术中心大剧院', place: '梅溪湖国际文化艺术中心大剧院' },
        // { label: '数智创世 新兴纪元',   lng: '112.906392', lat: '28.196569', address: '梅溪湖国际文化艺术中心小剧院', place: '梅溪湖国际文化艺术中心小剧院' },
        // // { label: '数字经济&中非“湘”见',  lng: '113.043811', lat: '28.170138', address: '长沙市雨花区长沙瑞吉酒店', place: '长沙市雨花区长沙瑞吉酒店'  },
        // { label: '数“聚”湘江，迎接未来',  lng: '112.904265', lat: '28.195422', address: '长沙梅溪湖金茂精选酒店' },
        // { label: '构筑创新高地&智汇文创高峰',  lng: '113.031511', lat: '28.220844', address: '马栏山视频文创园管理委员会'  }
        // { label: '创意新高地 数字新世界',  lng: '112.93644', lat: '28.207544', address: '58小镇前行者路演中心(长沙市岳麓区枫林一路西湖公园58小镇企业服务中心) ' },
        // { label: '万宝区块链论坛',  lng: '112.038527', lat: '27.744093', address: '华天大酒店(湖南省娄底市娄星区月塘辅路) '  }

        //  2022 
        // { label: '开幕式|岳麓论坛',  lng: '112.906834', lat: '28.196149', address: '梅溪湖国际文化艺术中心大剧院', place: '梅溪湖国际文化艺术中心大剧院' },
        // { label: '金融赋能创新 科创驱动发展',  lng: '112.963206', lat: '28.241975', address: '湘江基金小镇13栋5楼会议中心', place: '湘江基金小镇13栋5楼会议中心'  },
        // { label: 'AI致远 产业图新	',  lng: '112.904265', lat: '28.195422', address: '梅溪湖国际文化艺术中心大剧院', place: '梅溪湖国际文化艺术中心大剧院'  },
        // { label: '智能智造 领航未来	',  lng: '112.904265', lat: '28.195422', address: '中联智慧产业城--挖掘机智能工厂薄板件车间', place: '中联智慧产业城--挖掘机智能工厂薄板件车间'  },
        // { label: '助力新材料创新发展 构筑制造业发展脊梁', lng: '112.904265', lat: '28.195422', address: '长沙梅溪湖金茂精选酒店', place: '长沙梅溪湖金茂精选酒店'  },
        // // { label: '华南理工校友会',  lng: '112.906834', lat: '28.19649', address: '梅溪湖文化艺术中心大剧院', place: '梅溪湖文化艺术中心大剧院'  },
        // { label: '科技铸就高地 创新引领未来',  lng: '112.906358', lat: '28.196575', address: '梅溪湖国际文化艺术中心小剧院', place: '梅溪湖国际文化艺术中心小剧院'  },

        // 2024
        { label: '峰会新十年  湘商新征程',  lng: '112.906834', lat: '28.196149', address: '梅溪湖国际文化艺术中心大剧院', place: '梅溪湖国际文化艺术中心大剧院' },
        { label: '文化智核  科技新篇',  lng: '112.904407', lat: '28.195549', address: '长沙梅溪湖金茂豪华精选酒店三楼大宴会厅 2 厅', place: '长沙梅溪湖金茂豪华精选酒店三楼大宴会厅 2 厅' },
        { label: '投资新趋势 产业新格局',  lng: '112.904407', lat: '28.195549', address: '长沙梅溪湖金茂豪华精选酒店三楼大宴会厅 3 厅', place: '长沙梅溪湖金茂豪华精选酒店三楼大宴会厅 3 厅' },
        { label: '产业互联网 智汇长沙城',  lng: '112.906834', lat: '28.196149', address: '梅溪湖国际文化艺术中心小剧院', place: '梅溪湖国际文化艺术中心小剧院' },
        { label: 'AI驱动 数实融合',  lng: '112.932444', lat: '28.212044', address: '湖南佳兴世尊酒店四楼湖南厅', place: '湖南佳兴世尊酒店四楼湖南厅' },
        { label: '岳麓论坛',  lng: '112.906834', lat: '28.196149', address: '梅溪湖国际文化艺术中心大剧院', place: '梅溪湖国际文化艺术中心大剧院' },
      ],
      ylfh: {
        name: '梅溪湖金茂精选酒店',
        lng: '112.904407',
        lat: '28.195549'
      },
      user: {
        lng: '',
        lat: ''
      },
      startPlace: {},
      endPlace: {},
      routeShow: false, // 是否出现路线
      mapList: [
        { value: 1, label: '高德地图' },
        { value: 2, label: '腾讯地图' },
        { value: 3, label: '百度地图' }
      ],
      mapListShow: false,
      maskShow: false,
      active_index: '',
      active_hotel: '',
      active_meetting: '',
      timer: null
    }
  },
  activated() {
    this.getLocation()
  },
  destroyed() {
    clearInterval(this.timer)
  },
  methods: {
    handleHotel() {
      this.active_btn = 1
      this.popupShowHotel = true
      this.maskShow = true
    },

    handleMeetting() {
      this.active_btn = 2
      this.popupShowMeetting = true
      this.maskShow = true
    },

    handleYlfh() {
      this.active_btn = 3
      this.baseMapDraw(this.ylfh, 1)
    },
    // 点击打开地图
    handleMap() {
      // if (!this.startPlace.lng) {
      //   Toast('没有获取到当前位置！')
      //   return
      // }
      if (!this.endPlace.lng) {
        Toast('没有获取到终点位置！')
        return
      }
      this.mapListShow = true
      this.maskShow = true
    },

    handleCancal() {
      this.maskShow = false
      this.mapListShow = false
      this.popupShowHotel = false
      this.popupShowMeetting = false
    },

    // 选择酒店 会场
    handlePoint(data, i) {
      this.active_index = i
      this.endPlace = data
      if (this.startPlace.lng) {
        this.getCarRoute(this.startPlace, this.endPlace)
      } else {
        this.baseMapDraw(data)
      }
      
      this.popupShow = false
      this.maskShow = false
      this.routeShow = false
    },
    // 选择酒店
    handlePointHotel(data, i) {
      this.active_meetting = ''
      this.active_hotel = i
      this.endPlace = data
      if (this.startPlace.lng) {
        this.getCarRoute(this.startPlace, this.endPlace)
      } else {
        this.baseMapDraw(data)
      }
      
      this.popupShowHotel = false
      this.maskShow = false
      this.routeShow = false
    },
    // 选择会场
    handlePointMeetting(data, i) {
      this.active_hotel = ''
      this.active_meetting = i
      this.endPlace = data
      if (this.startPlace.lng) {
        this.getCarRoute(this.startPlace, this.endPlace)
      } else {
        this.baseMapDraw(data)
      }
      
      this.popupShowMeetting = false
      this.maskShow = false
      this.routeShow = false
    },
    handleOpenMap(data) {
      let gaodeMap = `//uri.amap.com/navigation?from=${this.startPlace.lng},${this.startPlace.lat},当前位置&to=${this.endPlace.lng},${this.endPlace.lat},${this.endPlace.label}&mode=car`
      let txMap = `https://apis.map.qq.com/uri/v1/routeplan?type=drive&fromcoord=${this.startPlace.lat},${this.startPlace.lng}&to=${this.endPlace.label}&tocoord=${this.endPlace.lat},${this.endPlace.lng}&policy=1&referer=ylfh`
      let baiduMap = `http://api.map.baidu.com/direction?origin=latlng:${this.startPlace.lat},${this.startPlace.lng}|name&destination=latlng:${this.endPlace.lat},${this.endPlace.lng}|name:${this.endPlace.label}&mode=driving&region=湖南&output=html&src=webapp.baidu.openAPIdemo`
      
      if (!this.startPlace.lng) {
        gaodeMap = `//uri.amap.com/navigation?from=&to=${this.endPlace.lng},${this.endPlace.lat},${this.endPlace.label}&mode=car`
        txMap = `https://apis.map.qq.com/uri/v1/routeplan?type=drive&fromcoord=&to=${this.endPlace.label}&tocoord=${this.endPlace.lat},${this.endPlace.lng}&policy=1&referer=ylfh`
        baiduMap = `http://api.map.baidu.com/direction?origin=&destination=latlng:${this.endPlace.lat},${this.endPlace.lng}|name:${this.endPlace.label}&mode=driving&region=湖南&output=html&src=webapp.baidu.openAPIdemo`
      }

      let href = ''
      if (data.value===1) {
        href = gaodeMap
      } else if (data.value===2) {
        href = txMap
      } else {
        href = baiduMap
      }
      window.location.href = href
    },

    handleCarRoute() {
      if (!this.startPlace.lng) {
        Toast('没有获取到当前位置！')
        return
      }
      if (!this.endPlace.lng) {
        Toast('没有获取到终点位置！')
        return
      }
      this.getCarRoute(this.startPlace, this.endPlace)
    },

    getLocation(loop) {
      var map = new AMap.Map(loop ? 'loop':'container', {
        resizeEnable: true
      });
      const vm = this
      AMap.plugin('AMap.Geolocation', function() {
        var geolocation = new AMap.Geolocation({
          enableHighAccuracy: true,//是否使用高精度定位，默认:true
          timeout: 1000,          //超过10秒后停止定位，默认：5s
          buttonPosition:'RB',    //定位按钮的停靠位置
          buttonOffset: new AMap.Pixel(10, 20),//定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
          zoomToAccuracy: loop ? false: true,   //定位成功后是否自动调整地图视野到定位点

        });
        map.addControl(geolocation);
        geolocation.getCurrentPosition(function(status,result){
          console.log(status, result)
            if(status=='complete'){
              vm.startPlace.lng = result.position.lng
              vm.startPlace.lat = result.position.lat
              onComplete(result)
              clearInterval(vm.timer)
              if (loop) {
                Toast('定位成功！')
              }
            }else{
              Toast('定位失败，请打开手机定位服务并允许访问您的位置信息！')
              clearInterval(vm.timer)
              vm.timer = setInterval(() => {
                vm.getLocation('loop')
              }, 8000)
              onError(result)
            }
        });
      });
      //解析定位结果
      function onComplete(data) {
        console.log('定位成功')
        // document.getElementById('status').innerHTML='定位成功'
        var str = [];
        str.push('定位结果：' + data.position);
        str.push('定位类别：' + data.location_type);
        if(data.accuracy){
            str.push('精度：' + data.accuracy + ' 米');
        }//如为IP精确定位结果则没有精度信息
        str.push('是否经过偏移：' + (data.isConverted ? '是' : '否'));
        // document.getElementById('result').innerHTML = str.join('<br>');
      }
      //解析定位错误信息
      function onError(data) {
        console.log('定位失败', data.message)
        // document.getElementById('status').innerHTML='定位失败'
        // document.getElementById('result').innerHTML = '失败原因排查信息:'+data.message;
      }
    },

    getCarRoute(startPlace, endPlace) {
      const vm = this
      var map = new AMap.Map("container", {
        resizeEnable: true,
        zoom: 13 //地图显示的缩放级别
      })
      // 构造路线导航类
      var driving = new AMap.Driving({
        map: map
      })
      // 根据起终点经纬度规划驾车导航路线
      driving.search(new AMap.LngLat(startPlace['lng'], startPlace['lat']), new AMap.LngLat(endPlace['lng'], endPlace['lat']), function(status, result) {
        // result 即是对应的驾车导航信息，相关数据结构文档请参考  https://lbs.amap.com/api/javascript-api/reference/route-search#m_DrivingResult
        if (status === 'complete') {
          vm.routeShow = true
          // log.success('绘制驾车路线完成')
          // setTimeout(() => {
          //   var button = document.getElementById('goApp');
          //   console.log(button)
          //   button.onclick = function(){
          //     driving.searchOnAMAP({
          //         origin:result.origin,
          //         destination:result.destination
          //     });
          //   }
          // }, 10)
        } else {
          log.error('获取驾车数据失败：' + result)
        }
      })
    },

    baseMapDraw(data, type) {
      var map = new AMap.Map("container", {
        resizeEnable: true,
        center: [data['lng'], data['lat']],
        zoom: 13 // 地图显示的缩放级别
      })

      var startIcon = new AMap.Icon({
        // 图标尺寸
        size: new AMap.Size(23, 38),
        // 图标的取图地址
        image: require('@/assets/images/map/marker.png'),
        // 图标所用图片大小
        imageSize: new AMap.Size(23, 38),
        // 图标取图偏移量
        imageOffset: new AMap.Pixel(0, 0)
      });

      // 构造点标记
      var marker = new AMap.Marker({
        icon: type ?  startIcon : "https://webapi.amap.com/theme/v1.3/markers/n/mark_r.png",
        position: [data['lng'], data['lat']]
      })
      map.add(marker)

    }

  }
}
</script>

<style lang="less" scoped>
  .map-container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    #container {
      width: 100%;
      height: 100%;
    }

    .place-wrapper {
      position: absolute;
      right: 15px;
      top: 50px;
      z-index: 9;
      width: 115px;
      font-size: 14px;
      color: #fff;
      .btn_item {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 37px;
        border-radius: 27px;
        margin-bottom: 14px;
        font-size: 12px;
        background: linear-gradient(to right, #5739bd, #2e3bab);
        img {
          margin-left: 10px;
          width: 18px;
          height: auto;
        }
      }
    }

    .mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.7);
      z-index: 9999;
    }

    .place-list {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      z-index: 9999;
      background: #fff;
      font-size: 16px;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      text-align: center;
      max-height: 450px;
      color: #202020;
      font-size: 14.5px;
      .list-head {
        height: 24px;
        background: #f6f6f6;
        border-top: 1px solid #e2e2e2;
        border-bottom: 1px solid #e2e2e2;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
          flex: 1;
          font-size:12px;
          color: #666;
        }
        img {
          margin-right: 15px;
          width: 17px;
        }
      }
      .place-item {
        height: 50px;
        line-height: 50px;
        text-overflow: ellipsis;
        white-space:nowrap; 
        overflow: hidden;
        position: relative;
        &::after {
          position: absolute;
          bottom: 0;
          left: 0;
          content:'';
          width:100%;
          border-bottom:1px solid #e6e6e6;
        }
      }
      p {
        height: 8px;
        margin: 0;
        background: #f8f6f7;
      }
    }
    
    .address-wrap {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      z-index: 9999;
      background: #fff;
      font-size: 16px;
      border-top-left-radius: 6px;
      border-top-right-radius: 6px;
      max-height: 450px;
      overflow-y: auto;
      color: #202020;
      font-size: 14.5px;
      display: flex;
      flex-direction: column;
      .list-head {
        height: 40px;
        background: #f6f6f6;
        border-top: 1px solid #e2e2e2;
        border-bottom: 1px solid #e2e2e2;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        img {
          margin-right: 15px;
          width: 17px;
        }
      }
      .address-list {
        flex: 1;
        overflow-y: auto;
      }
      .address-item {
        padding: 0 15px;
        height: 70px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        &::after {
          position: absolute;
          bottom: 0;
          left: 0;
          content:'';
          width:100%;
          border-bottom:1px solid #e6e6e6;
        }
        &:nth-last-child(1) {
          border: none;
        }
        .left {
          display: flex;
          flex-direction: column;
          justify-content: center;
          span:nth-of-type(2), span:nth-of-type(3) {
            font-size: 10px;
            color: #aaaaaa;
          }
          span:nth-of-type(2) {
            margin-top: 4px;
          }
        }
        .right {
          font-size: 18px;
          color: #57be6a;
        }
      }
    }

    .btn_cancel {
      position: absolute;
      left: 15px;
      bottom: 30px;
      width: 345px;
      height: 50px;
      z-index: 9999;
      background: #fff;
      line-height: 50px;
      font-size: 16px;
      text-align: center;
      border-radius: 6px;
      color: #0092fc;
      font-size: 15px;
      font-weight: bold;
    }

    .car-wrapper {
      position: absolute;
      left: 15px;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%) ;
      width: 200px;
      height: 36px;
      border-radius: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 999;
      background: linear-gradient(to right, #5739bd, #2e3bab);
      font-size: 14px;
      color: #fff;
    }
    .btn-active {
      background: #9aa2a5 !important;
    }


    .btn_meeting {
      position: absolute;
      left: 50%;
      bottom: 85px;
      transform: translateX(-50%);
      width: 75px;
      height: 75px;
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 999;
      background-image: linear-gradient(to bottom, #2e3ca7, #5d40c0);
      font-size: 12px;
      color: #fff;
      img {
        width: 20px;
        margin-bottom: 6px;
      }
    }

  }

  #callApp{
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: white;
    width: 11rem
  } 


  .v-enter, .v-leave-to{
    opacity: 0;
  }
  .v-enter-active, .v-leave-to{
    transition: .3s;
  }

  .slide-enter, .slide-leave-to{
    opacity: 0;
    transform: translateY(100%);
  }
  .slide-enter-active, .slide-leave-to{
    transition: .3s;
  }

  // .border-bottom  {
  //   height: 1px;
  //   background: #e6e6e6;
  // } 
  @media only screen and (-webkit-min-device-pixel-ratio: 2.0) {
    .address-item::after {
        -webkit-transform: scaleY(0.5);
        transform: scaleY(0.5);
    }
  }
  /* 3倍屏 */
  @media only screen and (-webkit-min-device-pixel-ratio: 3.0) {
    .address-item::after {
      -webkit-transform: scaleY(0.33);
      transform: scaleY(0.33);
    }
  }

  /deep/.amap-container img {
    display: block;
  }
</style>
